import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import popup  from './libs/popup.js';

const resource = {};

resource.sliderResource = function() {
	$('.slider-resource').slick({
		arrows:true,
		dots:false,
	})
}
resource.popupAnnualreport = function(){
	$('.resource .list a').on('click', function(e){
		e.preventDefault();
		popup($).openpopup('#pop-resource')
	});
	popup($).btnpopup('#pop-resource')
}

resource.init = function(){
	this.sliderResource()
	this.popupAnnualreport()
}

resource.init()